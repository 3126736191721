<template>
  <div class="bg-white p-3">
    <b-table
      ref="tablecustom"
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(store_credit)="{ item }">
        <div
          :class="{
            'text-error': item.store_credit_type_id == 2,
            'text-success': item.store_credit_type_id == 1,
          }"
        >
          {{ item.store_credit }}
        </div>
      </template>
      <template v-slot:cell(current_store_credit)="{ item }">
        <div>
          {{ item.current_store_credit | numeral("0,0") }}
        </div>
      </template>
      <template v-slot:cell(invoiice_no)="{item}">
          <div class="text-left text-invoice_no pointer">
            <router-link
              :to="`/report/transaction/${item.transaction_id}`"
              >{{
               item.invoiice_no ? item.invoiice_no : "-"
              }}</router-link
            >
          </div>
        </template>
      <template v-slot:cell(created_time)="{ item }">
        <div>{{ item.created_time | moment($formatDateNew) }}</div>
        <div class="time-color">
          {{ item.created_time | moment("HH:mm:ss") }}
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isBusy: false,
      filter: {
        user_guid: this.$route.params.id,
        search: "",
        page: 1,
        take: 10,
      },
      toShow: this.showingTo,
      fields: [
        {
          key: "invoiice_no",
          label: "Invoice No.",
        },

        {
          key: "store_credit_type_name",
          label: "Note",
          class: "w-2",
        },
        {
          key: "store_credit",
          label: "Store Credit",
        },
        {
          key: "current_store_credit",
          label: "Current Store Credit",
        },
        {
          key: "created_time",
          label: "Create Time",
        },
      ],
      items: [],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList(filter) {
      let payload = this.filter;
      if (filter) {
        payload = { ...filter, ...this.filter };
      }
      this.isBusy = true;
      const res = await this.axios.post(`/customer/GetStoreCreditLog`, payload);
      this.rows = res.data.detail.total_count;
      this.items = res.data.detail.store_credit_log_list;
      this.isBusy = false;
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    pagination(value) {
      this.filter.page = value;

      this.getList();
    },
    colorClass(item) {
      if (item.status == "1") return "text-warning";
      else if (item.status == "2") return "text-success";
      else if (item.status == "3") return "text-error";
      else return "text-secondary";
    },
    convertDate(value) {
      if (this.$moment(value).years() === 1) return "-";
      else return this.$moment(value).format(this.$formatDateNew);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep a {
  color: #333 !important;
}
.text-invoice_no {
  color: #333;
  text-decoration: underline;
}
.status-complete {
  color: #00bd00;
}
.status-void {
  color: #f9b115;
}
.status-cancel {
  color: red;
}
.bg-log-type {
  opacity: 0.5;
  border-radius: 50px;
  padding: 0 10px;
}
.log-type-odd {
  background-color: #d79b9b;
  color: red;
}
.log-type-even {
  background-color: #9bd79b;
  color: green;
}
.datetime {
  color: #919191;
}
</style>
