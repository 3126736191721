<template>
  <div>
    <div class="content-between">
      <div class="label-header">Shipping Address</div>
      <div class="text-gray">
        <span v-if="limit != 0">{{ list.length }}/{{ limit }}</span>
      </div>
    </div>
    <div class="d-flex flex-column row-gap-lg">
      <template v-for="items of list">
        <div
          :key="'shipping-list' + items.id"
          class="box-address"
          @click="getById(items.id)"
        >
          <div class="color-primary">{{ items.address_name }}</div>
          <div>
            <span class="text-bold text-black">
              <span v-if="items.prefix && items.blind_data_id == 1"
                >{{ items.prefix }}&nbsp;</span
              >{{ items.first_name }}
              {{ items.last_name }}
            </span>
            <span class="text-gray">
              <b-img
                :src="`https://flagcdn.com/48x36/${
                  items.telephone_country_code
                    ? items.telephone_country_code.toLowerCase()
                    : 'th'
                }.png`"
                style="width: 18px; height: 14px; margin-right: 5px"
              ></b-img>
              ({{ items.telephone_country_dial_code }})
              {{ convertTelePhone(items.telephone) }}
            </span>
          </div>
          <p class="text-gray mb-0">
            <span v-html="displayAddress(items)"></span>

            <br />
            <span class="text-bold text-black">
              ขนส่งโดย :
              {{ items.shipping_type == "0" ? "-" : items.shipping_type }}</span
            >
          </p>
          <div class="box-default" v-if="items.is_default == 1">Default</div>
        </div>
      </template>
      <div
        class="text-center box-address add"
        v-if="
          $store.state.permission.is_has_permission_edit_customer &&
          (limit == 0 || list.length < limit)
        "
      >
        <b-button variant="custom-primary" @click="addAddress">
          <font-awesome-icon icon="plus-square" class="pointer mr-2" />Add
          Address
        </b-button>
      </div>
      <div
        class="text-center box-address add"
        v-else-if="
          !$store.state.permission.is_has_permission_edit_customer &&
          list.length == 0
        "
      >
        <span class="color-primary">No information.</span>
      </div>
    </div>
    <Sidebar
      ref="sidebar"
      header="Shipping Address"
      @submit="submit"
      @hidden="clearField"
    >
      <div class="my-3">
        <b-row>
          <b-col cols="12" class="text-right">
            <span
              class="cursor-pointer text-underline"
              @click="useProfile"
              v-if="!form.id"
            >
              Fill With Profile
            </span>
          </b-col>
          <b-col cols="12" md="6">
            <InputText
              textFloat="Address Name"
              placeholder="Address"
              type="text"
              name="home_address"
              isRequired
              v-model="form.address_name"
              :v="$v.form.address_name"
              :isValidate="$v.form.address_name.$error"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <div class="break-normal"></div>
          <b-col cols="12" md="6">
            <InputSelect
              title="Name Title"
              name="Name-Title"
              :options="addressOptions.prefix.field_choices"
              v-model="form.prefix"
              valueField="name"
              textField="name"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            >
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >Please Select
                </b-form-select-option>
              </template>
            </InputSelect>
          </b-col>
          <b-col cols="12" md="6" v-if="checkOtherValue()">
            <InputText
              textFloat="Name Title"
              placeholder="Title"
              type="text"
              name="tax-id"
              class="mb-2"
              v-model="form.prefix_other"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <div class="break-normal" v-else></div>
          <b-col cols="12" md="6">
            <InputText
              textFloat="Recipient's Name"
              placeholder="Recipient's Name"
              type="text"
              name="firstname"
              isRequired
              v-model="form.first_name"
              :v="$v.form.first_name"
              :isValidate="$v.form.first_name.$error"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="12" md="6">
            <InputText
              textFloat="Recipient's Surname"
              placeholder="Recipient's Surname"
              type="text"
              name="surname"
              isRequired
              v-model="form.last_name"
              :v="$v.form.last_name"
              :isValidate="$v.form.last_name.$error"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="6">
            <InputTelephoneWithCountry
              textFloat="Telephone"
              placeholder="Telephone"
              :country="form.telephone_country_id"
              @set="(val) => (form.telephone_country_id = val)"
              v-model="form.telephone"
              isRequired
              :v="$v.form.telephone"
              :isValidate="$v.form.telephone.$error"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="6">
            <InputTag
              v-model="optionalTelephone"
              :limit="parseInt(formMain.limit_secondary_telephone)"
            />
          </b-col>
          <b-col cols="12" md="6">
            <InputSelect
              title="Country"
              name="Name-Title"
              :options="Country"
              v-model="form.country"
              valueField="englishName"
              textField="englishName"
              isRequired
              :v="$v.form.country"
              :isValidate="$v.form.country.$error"
              @onDataChange="changeCountry"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            >
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >Please Select
                </b-form-select-option>
              </template>
            </InputSelect>
          </b-col>
          <div class="break-normal"></div>
          <!-- <template v-if="form.country == 'ประเทศไทย' || form.country == ''"> -->
          <b-col cols="12" md="6">
            <InputText
              textFloat="Address"
              placeholder="Address"
              type="text"
              isRequired
              name="address"
              v-model="form.address"
              :v="$v.form.address"
              :isValidate="$v.form.address.$error"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="12" md="6">
            <InputText
              textFloat="Building / Village Name"
              placeholder="Building / Village Name"
              type="text"
              name="building"
              v-model="form.building_name"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="12" md="6">
            <InputText
              textFloat="Alley / Soi"
              placeholder="Alley / Soi"
              type="text"
              name="alley"
              v-model="form.alley"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>

          <b-col cols="12" md="6">
            <InputText
              textFloat="Road"
              placeholder="Road"
              type="text"
              name="road"
              v-model="form.road"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="12" md="6">
            <template v-if="form.country == 'ประเทศไทย' || form.country == ''">
              <InputSelect
                title="Province"
                name="Province"
                :options="addressOptions.province.field_choices"
                v-model="form.province"
                valueField="name"
                textField="name"
                isRequired
                :v="$v.form.province"
                :isValidate="$v.form.province.$error"
                @onDataChange="
                  handleChange(
                    $event,
                    addressOptions.province.field_choices,
                    'district',
                    'sub_district'
                  )
                "
                :disabled="
                  !$store.state.permission.is_has_permission_edit_customer
                "
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Please Select
                  </b-form-select-option>
                </template>
              </InputSelect>
            </template>
            <template v-else>
              <InputText
                textFloat="Province"
                placeholder="Province"
                type="text"
                name="province"
                v-model="form.province"
                :v="$v.form.province"
                :isValidate="$v.form.province.$error"
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 50);'"
                :disabled="
                  !$store.state.permission.is_has_permission_edit_customer
                "
              />
            </template>
          </b-col>
          <b-col cols="12" md="6">
            <template v-if="form.country == 'ประเทศไทย'">
              <InputSelect
                title="District"
                name="District"
                :options="[]"
                value=""
                :disabled="true"
                v-if="loading.district"
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Loading...
                  </b-form-select-option>
                </template>
              </InputSelect>
              <InputSelect
                v-else
                title="District"
                name="District"
                :options="options.district.field_choices || []"
                v-model="form.district"
                valueField="name"
                textField="name"
                isRequired
                :v="$v.form.district"
                :isValidate="$v.form.district.$error"
                :disabled="
                  !$store.state.permission.is_has_permission_edit_customer ||
                  !form.province
                "
                @onDataChange="
                  handleChange(
                    $event,
                    options.district.field_choices,
                    'subdistrict',
                    'sub_district'
                  )
                "
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Please Select
                  </b-form-select-option>
                </template>
              </InputSelect></template
            >
            <template v-else>
              <InputText
                textFloat="District"
                placeholder="District"
                type="text"
                name="District"
                v-model="form.district"
                :v="$v.form.district"
                :isValidate="$v.form.district.$error"
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 50);'"
                :disabled="
                  !$store.state.permission.is_has_permission_edit_customer
                "
              />
            </template>
          </b-col>
          <b-col cols="12" md="6">
            <template v-if="form.country == 'ประเทศไทย'">
              <InputSelect
                title="Subdistrict"
                name="Subdistrict"
                :options="[]"
                value=""
                :disabled="true"
                v-if="loading.subdistrict"
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Loading...
                  </b-form-select-option>
                </template>
              </InputSelect>
              <InputSelect
                v-else
                title="Subdistrict"
                name="subdistrict"
                :options="options.subdistrict.field_choices || []"
                v-model="form.sub_district"
                :v="$v.form.sub_district"
                :isValidate="$v.form.sub_district.$error"
                valueField="name"
                textField="name"
                isRequired
                @onDataChange="
                  handleChange(
                    $event,
                    options.subdistrict.field_choices,
                    'zip_code'
                  )
                "
                :disabled="
                  !$store.state.permission.is_has_permission_edit_customer ||
                  !form.district
                "
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Please Select
                  </b-form-select-option>
                </template>
              </InputSelect></template
            >
            <template v-else>
              <InputText
                textFloat="Subdistrict"
                placeholder="Subdistrict"
                type="text"
                name="Subdistrict"
                v-model="form.sub_district"
                :v="$v.form.sub_district"
                :isValidate="$v.form.sub_district.$error"
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 50);'"
                :disabled="
                  !$store.state.permission.is_has_permission_edit_customer
                "
              />
            </template>
          </b-col>

          <b-col cols="12" md="6">
            <InputText
              textFloat="Zip Code"
              placeholder="Zip Code"
              type="number"
              name="zip_code"
              v-model="form.zip_code"
              :v="$v.form.zip_code"
              :isValidate="$v.form.zip_code.$error"
              :isRequired="form.country == 'ประเทศไทย'"
              :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 5);'"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <!-- </template> -->

          <b-col cols="12">
            <div class="label-header">Social Media</div>
          </b-col>
          <template v-for="$social of socialList">
            <b-col cols="12" md="6" :key="$social.id">
              <InputText
                :textFloat="$social.name"
                :placeholder="$social.name"
                type="text"
                name="zip_code"
                v-model="$social.value"
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 30);'"
                :disabled="
                  !$store.state.permission.is_has_permission_edit_customer
                "
              />
            </b-col>
          </template>
          <b-col cols="12">
            <div class="label-header">Shipping Information</div>
          </b-col>
          <b-col cols="12" md="6">
            <InputSelect
              title="Shipping Type"
              name="Shipping-Type"
              :options="shippingList"
              v-model="form.shipping_type"
              valueField="name"
              textField="name"
              isRequired
              :v="$v.form.shipping_type"
              :isValidate="$v.form.shipping_type.$error"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            >
              <!-- isRequired -->
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >Please Select
                </b-form-select-option>
              </template>
            </InputSelect>
          </b-col>
          <div class="break-normal"></div>
          <b-col cols="12">
            <InputTextArea
              textFloat="Note"
              placeholder="Additional Note."
              :maxLength="255"
              type="text"
              name="note"
              :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 500);'"
              v-model="form.note"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="12">
            <div class="content-between">
              <b-form-checkbox
                id="is_default"
                name="is_default"
                :value="1"
                :unchecked-value="0"
                v-model="form.is_default"
                :disabled="
                  !$store.state.permission.is_has_permission_edit_customer
                "
              >
                Default
              </b-form-checkbox>
              <b-button
                variant="custom-link"
                @click="deleteAddress"
                v-if="
                  $store.state.permission.is_has_permission_edit_customer &&
                  form.id
                "
                >Delete</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </Sidebar>
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Country from "@/assets/lang/country.json";
import {
  required,
  minLength,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
const telephoneFormat = (val) => {
  let validate = new RegExp(/^\d+(\-\d{1,20})?$/);

  if (validate.test(val)) return true;
  return false;
};
const TelephoneMinLength = (val, e, a) => {
  if (val.split("-")[0].length < 9 && e.telephone_country_id == 1) return false;
  return true;
  // return minLength(val);
};
export default {
  components: { Sidebar },
  props: {
    addressOptions: {
      required: true,
      type: Object | undefined | null,
    },
    formMain: {
      required: false,
    },
    limit: { required: true, default: 0, type: Number | String },
    getField: {
      required: false,
    },
  },
  validations() {
    return {
      form: {
        address_name: { required },
        first_name: { required },
        last_name: { required },
        shipping_type: { required },
        telephone: {
          required,
          telephoneFormat,
          TelephoneMinLength,
          // minLength: minLength(9),
          // maxLength: maxLength(10),
        },
        country: { required },
        address: { required },
        province: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
        district: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
        sub_district: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
        zip_code: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
      },
    };
  },
  data() {
    return {
      form: {
        user_guid: "",
        address_name: "",
        prefix: "",
        first_name: "",
        last_name: "",
        optional_telephone: "",
        telephone_country_id: 1,
        telephone: "",
        country: "ประเทศไทย",
        address: "",
        building_name: "",
        alley: "",
        road: "",
        sub_district: "",
        district: "",
        province: "",
        zip_code: "",
        shipping_type: "",
        note: "",
        is_default: 0,
      },
      list: [],
      Country: Country,
      optionalTelephone: [],
      errorOptional: false,
      options: { ...this.addressOptions },

      loading: {
        district: false,
        subdistrict: false,
      },
      shippingList: [],
      socialList: [],
      social: [],
    };
  },
  created() {
    this.getList();
  },
  watch: {
    addressOptions(val) {
      this.options = { ...val };
    },
  },
  methods: {
    async getSocialMedia() {
      const result = await this.axios(`customer/GetSocialMediaListAll`);
      for (const social of result.data.detail) {
        social.social_media_type_id = social.id;

        if (this.social) {
          const $social = this.social.find(
            (el) => social.id == el.social_media_type_id
          );
          if ($social) {
            social.value = $social ? $social.value : "";
            social.id = $social ? $social.id : 0;
          } else {
            social.id = 0;
          }
        }
      }
      this.socialList = result.data.detail;
    },
    async getById(id) {
      this.$bus.$emit("showLoading");
      this.getField().then(async () => {
        const result = await this.axios(
          `/customer/GetShippingInformationById/${id}`
        );
        let data = result.data.detail.data;

        this.form = result.data.detail.data;
        this.social = result.data.detail.social;
        if (
          !this.addressOptions.prefix.field_choices.find(
            (el) => el.name == this.form.prefix
          ) &&
          this.form.prefix
        ) {
          this.form.prefix_other = data.prefix;
          this.form.prefix = this.addressOptions.prefix.field_choices.find(
            (el) => el.choice_type_id == 3
          ).name;
        }
        this.optionalTelephone = result.data.detail.data.optional_telephone
          ? result.data.detail.data.optional_telephone
              .split(",")
              .filter((el) => el)
          : "";
        await this.getSocialMedia();
        try {
          await this.getShippingList();
          if (data.country == "ประเทศไทย") {
            if (data.province) {
              await this.handleChange(
                data.province,
                this.addressOptions.province.field_choices,
                "district",
                "subdistrict",
                false
              );
              // setTimeout(async () => {
              await this.handleChange(
                data.district,
                this.addressOptions.district.field_choices,
                "subdistrict",
                "",
                false
              );
              // }, 1000);
            }
          }
        } catch (error) {}
        this.$refs.sidebar.show();
        this.$bus.$emit("hideLoading");
      });
    },

    async submit() {
      if (!this.$store.state.permission.is_has_permission_edit_customer) return;
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.$bus.$emit("showLoading");
      this.form.user_guid = this.$route.params.id;
      if (this.optionalTelephone)
        this.form.optional_telephone = this.optionalTelephone.join(",");
      if (this.checkOtherValue()) this.form.prefix = this.form.prefix_other;
      let payload = this.socialList.map((el) => {
        return {
          id: el.id || 0,
          social_media_type_id: el.social_media_type_id,
          value: el.value,
          user_guid: this.$route.params.id,
        };
      });
      this.form.social = payload;
      var result = null;
      if (this.form.id) {
        result = await this.axios.put(
          `/customer/UpdateShippingInformation`,
          this.form
        );
      } else {
        result = await this.axios.post(
          `/customer/CreateShippingInformation`,
          this.form
        );
      }
      this.$bus.$emit("hideLoading");
      if (result.data.result) {
        this.getList();
        this.successAlert();
        this.$refs.sidebar.hide();
      } else this.errorAlert(result.data.message);
    },
    async addAddress() {
      // this.getById();

      this.$bus.$emit("showLoading");

      // await this.$emit("getField");
      this.getField().then(async () => {
        await this.getSocialMedia();
        await this.getShippingList();
        this.$refs.sidebar.show();
        this.$bus.$emit("hideLoading");
      });
    },
    async getShippingList() {
      const response = await this.axios.post(`/setting/GetShippingMethod`, {
        search: "",
        page: 1,
        take: 99999,
      });
      this.shippingList = response.data.detail.data.map((el) => {
        return { name: el.name };
      });
    },
    async deleteAddress() {
      try {
        this.$bus.$emit("showLoading");
        const result = await this.axios.delete(
          `/customer/DeleteShippingInformation/${this.form.id}`
        );
        this.$bus.$emit("hideLoading");
        if (result.data.result) {
          this.successAlert();
          this.$refs.sidebar.hide();
          this.getList();
          // this.back();
        } else {
          this.errorAlert(result.data.message);
        }
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
    async handleChange(val, field, $set, $set2, clear = true) {
      let payload = field.find((el) => el.name == val);
      if (!payload) return;
      if ($set == "zip_code") {
        this.form[$set] = payload ? payload.zip_code : "";
      } else {
        this.loading[$set] = true;
        const result = await this.axios(
          `/setting/Getsubaddress/${payload.id}/${$set == "district" ? 1 : 2}`
        );

        this.$nextTick(() => {
          this.options[$set].field_choices = result.data.detail[$set];

          if (clear) {
            this.form[$set] = "";
            this.form.zip_code = "";
            if ($set2) this.form[$set2] = "";
          }
          this.loading[$set] = false;
        });
      }
    },
    async getList() {
      const result = await this.axios(
        `/customer/GetShippingInformationList/${this.$route.params.id}`
      );

      this.list = result.data.detail.result;
    },
    validateOptional(val) {
      const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
      if (parseInt(this.formMain.limit_secondary_telephone) != 0) {
        if (
          this.optionalTelephone.length >=
          this.formMain.limit_secondary_telephone
        )
          return;
      }
      if (val.length == 10 && isNumeric(val)) {
        return val;
      }

      (this.errorOptional = true),
        setTimeout(() => {
          this.errorOptional = false;
        }, 3000);
      return;
    },
    clearField() {
      this.form = {
        user_guid: "",
        address_name: "",
        prefix: "",
        first_name: "",
        last_name: "",
        optional_telephone: "",
        telephone_country_id: 1,
        telephone: "",
        country: "ประเทศไทย",
        address: "",
        building_name: "",
        alley: "",
        road: "",
        sub_district: "",
        district: "",
        province: "",
        zip_code: "",
        shipping_type: "",
        note: "",
        is_default: 0,
      };
      this.optionalTelephone = [];
      this.$v.form.$reset();
    },
    checkOtherValue() {
      if (this.addressOptions.prefix.field_choices) {
        let check = this.addressOptions.prefix.field_choices.find(
          (el) => el.name == this.form.prefix
        );

        return check && check.choice_type_id == 3;
      }
    },
    changeCountry() {
      this.form = {
        ...this.form,
        address: "",
        building_name: "",
        alley: "",
        road: "",
        district: "",
        sub_district: "",
        province: "",
        zip_code: "",
      };
    },
    async useProfile() {
      this.form = {
        ...this.form,
        ...this.formMain,
        province: this.formMain.province || "",
        district: this.formMain.district || "",
        address: this.formMain.home_address || "",
        building_name: this.formMain.town || "",
        sub_district: this.formMain.subdistrict || "",
        first_name: this.formMain.first_name_th,
        last_name: this.formMain.last_name_th,
      };

      this.optionalTelephone = this.formMain.optional_telephone
        ? this.formMain.optional_telephone.split(",").filter((el) => el)
        : "";

      if (this.form.country == "ประเทศไทย") {
        if (this.form.province) {
          await this.handleChange(
            this.form.province,
            this.addressOptions.province.field_choices,
            "district",
            "subdistrict",
            false
          );
        }
        if (this.form.district) {
          await this.handleChange(
            this.form.district,
            this.options.district.field_choices,
            "subdistrict",
            "sub_district",
            false
          );
        }
      }
      this.$nextTick();
    },
  },
};
</script>

<style lang="scss" scoped>
.box-address {
  border-width: 1px;
  border-radius: 0.5rem;
  border-color: rgba(0, 0, 21, 0.2);
  border-style: solid;
  padding: 1rem;
  position: relative;
  cursor: pointer;
  &.add {
    border-style: dashed;
    border-color: var(--primary-color);
  }
  .box-default {
    color: green;
    border: 1px solid green;
    border-radius: 0.2rem;
    position: absolute;
    top: 10%;
    right: 10px;
    font-size: 12px;
    padding: 0 5px;
  }
}
</style>
