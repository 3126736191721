<template>
  <b-sidebar
    id="sidebar-address-edit"
    ref="sidebarAddressEdit"
    v-model="isShowSidebar"
    right
    backdrop
    shadow
    no-header
    width="60vw"
  >
    <div>
      <b-row class="header-filter" no-gutters>
        <b-col class="text-filter px-3">Edit Additional Questions</b-col>
      </b-row>

      <div class="p-3">
        <div
          v-for="(field, index) of questionList"
          :key="field.id"
          class="mb-3"
        >
          <div>
            {{ field.field_language[0].name }}
            <span v-if="field.required" class="text-error">*</span>
          </div>

          <InputText
            textFloat=""
            :placeholder="field.field_language[0].name"
            :type="checkFieldcondition(field)"
            :name="'field-' + field.id"
            v-model="field.user_answer"
            class="mb-0"
            :validateText="field.validate_text"
            :isValidate="$v.questionList.$each[index].$error"
            v-if="field.field_type_id == 1"
          />

          <b-form-checkbox-group
            :id="`field-${field.id}`"
            :options="field.field_choices"
            value-field="name"
            v-model="field.user_answer"
            text-field="name"
            class="mt-2"
            stacked
            v-else-if="field.field_type_id == 2"
          ></b-form-checkbox-group>
          <b-form-radio-group
            :id="`${field.id}`"
            :options="field.field_choices"
            value-field="name"
            text-field="name"
            v-model="field.user_answer"
            stacked
            class="mt-2"
            :checked="field.user_answer"
            v-else-if="field.field_type_id == 3"
          ></b-form-radio-group>
          <b-form-select
            :id="`${field.id}`"
            :options="field.field_choices"
            value-field="name"
            v-model="field.user_answer"
            text-field="name"
            :class="[{ error: $v.questionList.$each[index].$error }, 'mt-2']"
            v-else-if="field.field_type_id == 4"
          >
            <template #first>
              <b-form-select-option value="" disabled selected
                >โปรดเลือก</b-form-select-option
              >
            </template>
          </b-form-select>
          <InputDatePickerFilter
            textFloat=""
            :name="field.field_language[0].name"
            :ref="field.field_language[0].name"
            :format="'dd MMM yyyy HH:mm'"
            :value="field.user_answer"
            v-model="field.user_answer"
            type="datetime"
            :isValidate="$v.questionList.$each[index].$error"
            v-else-if="field.field_type_id == 5"
          />

          <b-form-select
            v-else-if="field.field_type_id == 6"
            :id="`${field.id}`"
            :options="branchCustomField"
            text-field="name"
            value-field="name"
            v-model="field.user_answer"
          >
            <template #first>
              <b-form-select-option value="" disabled
                >โปรดเลือก</b-form-select-option
              >
            </template>
          </b-form-select>

          <InputDatePickerFilter
            textFloat=""
            :name="field.field_language[0].name"
            :ref="field.field_language[0].name"
            :value="field.user_answer"
            v-model="field.user_answer"
            :format="'dd MMM yyyy'"
            type="date"
            :isValidate="$v.questionList.$each[index].$error"
            v-else-if="field.field_type_id == 7"
          />

          <template v-else-if="field.field_type_id == 8">
            <UploadFile
              v-model="field.user_answer"
              :value="field.user_answer"
              textFloat=""
              text=""
              placeholder="Please Select a File To Upload."
              format="image"
              name="thumbnail"
              accept="image/jpeg, image/png"
              v-on:onFileChange="onImageChange"
              :fileName="field.user_answer"
              :fields="index"
              :isValidate="$v.questionList.$each[index].$error"
            />

            <PreviewBox
              :showPreviewType="0"
              :showPreview="field.user_answer"
              :ratioType="1"
              @delete="onDelete(index)"
            />
          </template>
          <InputTextArea
            :id="field.field_id"
            textFloat=""
            type="text"
            class="mt-2 custom-input"
            :placeholder="field.field_language[0].name"
            rows="3"
            v-model="field.user_answer"
            :isValidate="$v.questionList.$each[index].$error"
            v-else-if="field.field_type_id == 12"
          />

          <div
            class="text-error mt-2"
            v-if="$v.questionList.$each[index].$error"
          >
            {{ field.validate_text }}
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import PreviewBox from "@/components/PreviewBox.vue";
import { requiredIf, required } from "vuelidate/lib/validators";
export default {
  components: { PreviewBox },
  props: {
    form: {
      required: true,
    },
    branchCustomField: {
      required: false,
    },
  },
  validations() {
    return {
      questionList: {
        $each: {
          user_answer: {
            $each: {
              required,
            },
            required: requiredIf((el) => el.required),
          },
        },
      },
    };
  },
  data() {
    return {
      isShowSidebar: false,
      tags: "",
      questionList: [],
      deleteList: [],
    };
  },
  created() {},
  methods: {
    handleMaxlength(e) {
      if (e.target.value.length == 5) {
        return e.preventDefault();
      }
    },
    show() {
      this.questionList = this.cleanQuestionValue();
      this.isShowSidebar = true;
    },
    hide() {
      this.deleteList = [];
      this.tags = "";

      this.isShowSidebar = false;
    },
    cleanQuestionValue() {
      let form = JSON.parse(JSON.stringify(this.form));
      for (const field of form) {
        let value = field.user_answer.map((el) => el.answer);

        if (field.field_type_id == 2) {
          field.user_answer = value;
        } else {
          field.user_answer = value.toString();
        }
      }
      return form;
    },
    checkFieldcondition(obj) {
      return obj.field_conditions[0] ? obj.field_conditions[0].value : "text";
    },
    async submitAction() {
      try {
        this.$v.questionList.$touch();
        if (this.$v.questionList.$error) {
          return;
        }
        this.$bus.$emit("showLoading");

        let dynamic = [];
        for (const fields of this.questionList) {
          let target_id = 0;
          if (fields.field_choices.length > 0) {
            const findFieldId = fields.field_choices.find(
              (choice) => choice.name == fields.user_answer
            );
            if (findFieldId) {
              target_id = findFieldId.id;
            }
          }
          dynamic.push({
            id: fields.id,
            value: fields.user_answer,
            target_id: target_id,
          });
        }
        let objTemp = [];

        for (const x of dynamic) {
          var findField = this.questionList.find((field) => field.id == x.id);

          if (x.value && typeof x.value == "object") {
            if (x.value.length > 0) {
              for (const a of x.value) {
                const { id } = findField.field_choices.find(
                  (choice) => choice.name == a
                );
                objTemp.push({
                  id: x.id,
                  value: a,
                  target_id: id,
                });
              }
            }
          } else {
            objTemp.push(x);
          }
        }
        let payload = {
          user_guid: this.$route.params.id,
          custom_field: objTemp.filter((el) => el.value),
        };

        const res = await this.axios.put(
          `/customer/update_customer_custom_detail`,
          payload
        );
        this.$bus.$emit("hideLoading");
        if (res.data.detail) {
          this.$emit("fetch");
          this.successAlert();
          this.hide();
        } else {
          this.errorAlert();
        }
      } catch (error) {
        console.log(error);
      }
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      var data;
      if (this.withSize) {
        data = await this.$store.dispatch("ActionUploadImageSize", form);
      } else {
        await this.$store.dispatch("ActionUploadImage", form);
        data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      }
      if (data.result == 1) {
        return data.detail;
      }
    },
    onImageChange(img, index) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        let images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;

        this.questionList[index].user_answer = images;
        this.showPreview = images;
      };
    },
    onDelete(index) {
      this.questionList[index].user_answer = null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.badge {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
